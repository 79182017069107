// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400, 700');

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'LatoWebHeavy';
  src: url('/fonts/LatoLatin-Heavy.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Heavy.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Heavy.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Heavy.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'LatoWebSemibold';
  src: url('/fonts/LatoLatin-Semibold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Semibold.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Semibold.ttf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  src: url('/fonts/LatoLatin-Medium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Medium.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Medium.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: 'LatoWebItalic';
  src: url('/fonts/LatoLatin-Italic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/LatoLatin-Italic.woff2') format('woff2'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/LatoLatin-Italic.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

// Fonts
@mixin font-regular {
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.33px;
}
@mixin font-nav-bar {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
}

// Colors
$color-background-main: #f7f7f7;
$color-foreground-heading: #5a5a5a;
$color-foreground-muted: #bababa;
$color-link-regular: #009bff;
$color-link-hovered: #0079ff;
$color-avatar-border: #979797;
$color-review-text: #7f7f7f;
$color-review-background: #f1f1f1;
$color-border-footer: #e7e7e7;

// Styles

figcaption {
  font-size: small;
  color: gray;
  margin: 8px;
}

.headline-container {
  font-family: 'LatoWebSemibold', sans-serif;
  color: #5a5a5a;
}

// Adaptivity
// http://stackoverflow.com/questions/18264944/bootstrap-3-navbar-fixed-top-stay-fixed-in-mobile-view
@media (max-width: 767px) {
  .navbar-fixed-top {
    position: relative;
    top: auto;
  }
  .navbar-collapse {
    max-height: none;
  }
  body {
    margin: 0;
    margin-top: 0px !important;
  }
  .screen img {
    // To scale up images in sliders if needed.
    width: 100%;
  }
}
// Shared
code.highlighter-rouge {
  // For pieces of text selected with ``.
  border: 1px solid #e1e1e8;
}
a.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}
/* Make all images responsive by default */
img {
  height: auto;
  max-width: 100%;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 17px;
  margin-bottom: 5px;
}
p,
ul {
  margin: 0 0 16px;
}
.list-item {
  margin-top: 48px;
  margin-left: 15px;
  margin-right: 15px;

  h2 {
    margin-top: 0;
    color: $color-foreground-heading;
    font-family: 'LatoWebSemibold', sans-serif;
  }
  h3 {
    font-family: 'LatoWebMedium', sans-serif;
  }
  strong {
    font-weight: 600;
  }
}
.date {
  font-family: 'LatoWebMedium', sans-serif;
  color: $color-foreground-muted;
  font-size: 16px;
}
.margin-standard {
  margin: 16px 0;
}
.appstore-badge {
  display: block;
  overflow: hidden;
  background: url('/img/app-store-badge.svg')
    no-repeat;
  width: 135px;
  height: 40px;
  margin: auto;
  border-bottom: none !important;
}
.googleplay-badge {
  display: block;
  overflow: hidden;
  background: url('/img/google-play-badge.png') no-repeat;
  width: 135px;
  height: 40px;
  margin: auto;
  border-bottom: none !important;
  background-size: auto 100%;
}

// Post
.post-header {
  h1 {
    font-size: 24px;
    font-family: 'LatoWebSemibold', sans-serif;
  }
}
.post-content {
  h2,
  h3,
  h4 {
    font-family: 'LatoWebHeavy', sans-serif;
    margin: 24px 0 8px;
  }
}

// Home
.header {
  margin: 48px 0 23px;
  padding: 0;
  color: $color-foreground-heading;

  h1 {
    font-family: 'LatoWebHeavy', sans-serif;
    font-size: 34px;
    margin-top: 0px;
  }
  h2 {
    font-family: 'LatoWebMedium', sans-serif;
    font-size: 24px;
  }
}
.avatar {
  // border: solid $color-avatar-border;
  border: none;
  border-width: 0px;
  border-radius: 8px;
  width: 125px;
  height: 125px;
}
.content {
  margin: 42px 0;
}
.main-content {
  h2,
  h3,
  h4 {
    font-weight: 600;
  }
}
.featured-portfolio-items {
  margin-top: 24px;
  margin-bottom: 24px;
  h3 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
  }
  h4 {
    margin-top: 0px;
    @include font-regular;
    font-size: 14px;
    font-weight: 400;
  }
}
.logos {
  margin: 16px 0;
}
.logo-large {
  display: inline-block;
  text-align: center;
  margin: 0 24px 0 0;

  img {
    height: 80px;
  }

  figcaption {
    font-size: 14px;
    margin-top: 8px;
  }
}
.logo {
  display: inline-block;
  text-align: center;
  margin: 0 16px 0 0;

  img {
    width: 60px;
    height: 60px;
  }

  figcaption {
    font-size: 13px;
    margin-top: 8px;
  }
}
// Testimonials
.testimonials-author {
  margin: 0 auto -24px;
  border-radius: 8px;
  position: relative;
  max-width: 80%;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
}

// Portfolio
.slideshow {
  .device-container {
    max-width: 300px;
    margin: 0 8px 16px;
  }
  .device-container .screen img {
    padding: 1.333px 2px 2px 2.5px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-prev {
    width: 25px;
  }
  .slick-next {
    width: 25px;
  }
  .slick-prev:before {
    color: #7f7f7f;
    content: url('/img/less-than-25.png');
    width: 25px;
  }

  .slick-next:before {
    color: #7f7f7f;
    content: url('/img/more-than-25.png');
    width: 25px;
  }
}
.portfolio-content {
  margin-top: 24px;
  margin-left: -15px;
}
.portfolio-features {
  margin-top: 24px;
  margin-left: 0px;

  h4 {
    font-size: 18px;
    font-family: 'Lato', sans-serif;
  }
}
.review {
  background-color: $color-review-background;
  margin: 36px auto 0;
  border-radius: 8px;
  position: relative;
  max-width: 80%;

  p {
    padding: 20px 53px;
    font-family: 'LatoWebItalic', sans-serif;
    font-size: 18px;
    color: $color-review-text;
  }
}
@media (max-width: 767px) {
  .review {
    max-width: 90%;
  }
}
#quoteLeft {
  position: absolute;
  left: 15px;
  top: 24px;
}
#quoteRight {
  position: absolute;
  right: 15px;
  bottom: 24px;
}
.portfolio-separator {
  display: block;
  margin: 36px auto auto;
}

// Footer
.footer-heading {
  font-family: 'LatoWebMedium', sans-serif;
  font-size: 24px;
  letter-spacing: 0.19px;
  color: $color-foreground-heading;
  margin-top: 29px;
}
.footer-subheading {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  letter-spacing: 0.12px;
  color: $color-foreground-muted;
  margin: 12px 0;
}
.site-footer {
  margin-top: 48px;
  border: solid $color-border-footer;
  border-width: 1px 0 0;
  font-size: 14px;

  .copyright {
    font-size: 13px;
    margin: 48px 0 18px;
  }
}
.footer-content {
  margin-top: 29px;
  padding-left: 15%;

  .row {
    margin-bottom: 12px;
  }
  a {
    margin-left: 5px;
  }
}
// Navbar
.navbar-nav {
  @include font-nav-bar;
  text-transform: uppercase;
  a:hover {
    border-bottom: none;
  }
}
.navbar-brand {
  font-size: 12px;
  padding: 0;
  margin: auto 0;
  margin-left: 16px !important;
  h2 {
    margin: 8px 0 0;
    font-size: 15px;
    font-weight: 600;
    color: $color-foreground-heading;
  }
  h3 {
    margin: 4px 0 0;
    font-size: 13px;
    color: $color-foreground-heading;
  }
}
@media only screen and (max-width: 768px) {
  .navbar-brand {
    display: none;
  }
}

// Bootstrap
.text-muted {
  color: $color-foreground-muted;
  
  a {
    color: $color-link-regular;
    font-weight: bold;
  }
}

// Base
.container-fluid {
  max-width: 960px;
}
a {
  color: $color-link-regular;
}
a:hover {
  color: $color-link-hovered;
  /* http://stackoverflow.com/questions/9586596/control-underline-position-on-text-decoration-underline */
  border-bottom: 1px solid currentColor;
  text-decoration: none;
}
.featured-portfolio-items {
  a:hover {
    border-bottom: none;
  }
}
body {
  margin-top: 50px;
  @include font-regular;
  background-color: $color-background-main;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
html {
  background-color: $color-background-main;
}

// Pagination
.blog-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
  padding: 0;
  text-align: center;
  
  .pagination-item {
    display: inline-block;
    margin: 0 5px;
    
    a, span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      padding: 0 10px;
      border-radius: 4px;
      text-decoration: none;
      transition: all 0.25s ease;
      font-weight: 500;
    }
    
    a {
      background-color: #f8f8f8;
      color: #444;
      border: 1px solid #ddd;
      box-shadow: 0 1px 2px rgba(0,0,0,0.05);
      
      &:hover {
        background-color: $color-link-regular;
        color: white;
        border-color: $color-link-regular;
        border-bottom: 1px solid $color-link-regular;
        transform: translateY(-1px);
        box-shadow: 0 2px 3px rgba(0,0,0,0.1);
      }
    }
    
    span.current {
      background-color: $color-link-regular;
      color: white;
      border: 1px solid $color-link-regular;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    }
    
    &.disabled span {
      color: #aaa;
      background-color: #f5f5f5;
      border: 1px solid #e5e5e5;
      cursor: not-allowed;
      opacity: 0.7;
    }
    
    &.pagination-prev, &.pagination-next {
      a, span {
        padding: 0 15px;
        font-weight: 600;
        
        i {
          margin: 0 3px;
        }
      }
    }
    
    .page-info {
      margin: 0 10px;
      color: #777;
    }
  }
}
